import './styles.scss';

import AkinonButton from '@components/AkinonButton';
import { openNotification } from '@components/akinonNotification';
import AkinonTable from '@components/AkinonTable';
import usePagination from '@components/AkinonTable/hooks/usePagination';
import Box from '@components/utility/box';
import { postCancellationRequestImagesUrl } from '@constants/apiUrls';
import { client } from '@services/api/client';
import { Form, Modal, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { getColumns } from './common';

const BulkRejectRefundRequestModal = ({
  open,
  onStartBulkAction,
  isDataSourceLoading,
  dataSource,
  title,
  onCloseRejectRefundModal,
  selectedRowKeys,
}) => {
  const { t } = useTranslation('Orders');
  const [form] = Form.useForm();
  const [pagination, setPagination] = usePagination();
  const columns = getColumns({ t });
  const filteredDataSource = dataSource?.filter((item) => selectedRowKeys.includes(item.id));

  const onRejectCancellationPlans = async (formValues) => {
    try {
      await Promise.all(
        Object.entries(formValues).map(async ([key, value]) => {
          if (value.images && value.images.fileList) {
            await Promise.all(
              value.images.fileList.map(async (file) => {
                const formData = new FormData();
                formData.append('image', file?.originFileObj);
                formData.append('description', value.explanation || '');
                formData.append('cancellation_request', key);

                await client.post(postCancellationRequestImagesUrl, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                  },
                });
              })
            );
          } else {
            const formData = new FormData();
            formData.append('description', value.explanation || '');
            formData.append('cancellation_request', key);
            await client.post(postCancellationRequestImagesUrl, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });
          }
        })
      );
      onStartBulkAction();
    } catch (error) {
      openNotification({
        message: t('transaction_failed'),
        description: t('changes_not_saved'),
        type: 'error',
      });
      onCloseRejectRefundModal();
    }
  };

  const handleClose = () => {
    form.resetFields();
    onCloseRejectRefundModal();
  };

  const handleFinish = (values) => {
    onRejectCancellationPlans(values);
    form.resetFields();
  };

  return (
    <Modal
      open={open}
      title={title}
      className="reject-refund-modal"
      onCancel={handleClose}
      footer={null}
      width={1200}
    >
      <Box className="box-primary form-box">
        <Typography.Text className="reject-refund-modal__desc">
          {t('reject_refund_request_desc')}
        </Typography.Text>
        <Form form={form} onFinish={handleFinish}>
          <AkinonTable
            dataSource={filteredDataSource}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            total={dataSource?.length}
            loading={isDataSourceLoading}
          />
          <AkinonButton type="primary" htmlType="submit" style={{ marginTop: 15 }}>
            {t('save')}
          </AkinonButton>
        </Form>
      </Box>
    </Modal>
  );
};

export default BulkRejectRefundRequestModal;
