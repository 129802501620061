const useRejectOrderItems = ({ orderId, rejectOrderItems }) => {
  const onRejectOrderItems = ({ selectedRows, mutationOptions, cancellationReason }) => {
    const orderItemIds = selectedRows.map((row) => row.id);
    rejectOrderItems(
      { orderId, orderItems: orderItemIds, cancellationReason },
      {
        onSuccess: () => {
          mutationOptions.onSuccess?.();
        },
        onError: () => {
          mutationOptions.onError?.();
        },
      }
    );
  };

  return { onRejectOrderItems };
};

export default useRejectOrderItems;
