import { sleep } from '@utils/index.jsx';
import { useState } from 'react';

const useRejectSingleOrderItem = ({ orderId, rejectOrderItems }) => {
  const [rejectOrderItemId, setRejectOrderItemId] = useState(null);
  const [isSingleRejectModalVisible, setIsSingleRejectModalVisible] = useState(false);

  const onOpenSingleRejectModal = ({ orderItemId }) => {
    setRejectOrderItemId(orderItemId);
    setIsSingleRejectModalVisible(true);
  };
  const onCancelSingleRejectModal = () => {
    setRejectOrderItemId(null);
    setIsSingleRejectModalVisible(false);
  };
  const onSingleReject = ({ cancellationReason }) => {
    rejectOrderItems({ orderId, orderItems: [rejectOrderItemId], cancellationReason });

    sleep(500).then(() => {
      setIsSingleRejectModalVisible(false);
    });
  };

  return {
    onOpenSingleRejectModal,
    onSingleReject,
    isSingleRejectModalVisible,
    onCancelSingleRejectModal,
  };
};

export default useRejectSingleOrderItem;
