import './styles.scss';

import AkinonBox from '@components/AkinonBox';
import AkinonButton from '@components/AkinonButton';
import AkinonForm from '@components/AkinonForm';
import AkinonFormItem from '@components/AkinonFormItem';
import AkinonInput from '@components/AkinonInput';
import AkinonSpin from '@components/AkinonSpin';
import { UserRole } from '@constants/auth';
import { QueryKey } from '@constants/query';
import { Color } from '@constants/theme';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDataSourcesQuery } from '@services/api/hooks/usePaginatedDataSourcesQuery';
import { usePatchWidgetMutation } from '@services/api/hooks/usePatchWidgetMutation';
import { usePostWidgetMutation } from '@services/api/hooks/usePostWidgetMutation';
import { useWidgetSchemaQuery } from '@services/api/hooks/useWidgetSchemaQuery';
import { queryClient } from '@services/api/queryClient';
import { useUserRole } from '@utils/hooks/useUserRole';
import first from 'lodash/first';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useUnmount } from 'react-use';

import {
  FormItemType,
  generateFormObject,
  getRequestBodyFromFormValues,
  pageWidgetSchemaIdMap,
} from '../common';
import SchemaManager from '../components/SchemaManager';
import { FormKey, getFormSchema } from './common';
import { useInitialWidgetValues } from './hooks/useInitialWidgetValues';

const WidgetManagement = () => {
  const { t } = useTranslation('SellerStoreManagement');
  const { dataSources } = useDataSourcesQuery();

  const userRole = useUserRole();
  const isSuperUser = userRole === UserRole.SUPER_USER;

  const { widgetSchema, isWidgetSchemaLoading } = useWidgetSchemaQuery({
    schemaId: pageWidgetSchemaIdMap.widgetManagementId,
  });
  const schema = getFormSchema({ widgetSchema: widgetSchema?.schema, t });
  const _form = useForm({
    mode: 'onChange',
    resolver: zodResolver(schema),
    disabled: isSuperUser,
  });

  const { control, handleSubmit, reset } = _form;

  const { widgetSlug } = useParams();
  const { initialWidgetValues, isWidgetFetching } = useInitialWidgetValues({
    widgetSlug,
    widgetSchema: widgetSchema?.schema,
  });

  const { postWidget, isPostingWidget } = usePostWidgetMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.WIDGET]);
      },
    },
  });
  const { patchWidget, isPatchingWidget } = usePatchWidgetMutation({
    mutationOptions: {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKey.WIDGET]);
      },
    },
  });

  useEffect(() => {
    if (initialWidgetValues) {
      reset(initialWidgetValues);
    }
  }, [initialWidgetValues]);

  const onSubmit = useCallback(
    (formValues) => {
      const dataSourceSlug = first(dataSources)?.slug;
      const requestBody = getRequestBodyFromFormValues({
        formValues: {
          ...formValues,
          widget_type: pageWidgetSchemaIdMap.widgetManagementId,
          slug: `${dataSourceSlug}-${pageWidgetSchemaIdMap.widgetManagementId}`,
        },
        widgetSchema: widgetSchema?.schema,
      });

      if (!isEmpty(initialWidgetValues)) {
        patchWidget({
          widgetId: initialWidgetValues.pk,
          requestBody,
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('widget_management_save_message.success'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('widget_management_save_message.error'),
          },
        });
      } else {
        postWidget({
          requestBody,
          requestConfig: {
            successMessage: t('transaction_success'),
            successDescription: t('widget_management_save_message.success'),
            errorMessage: t('transaction_failed'),
            errorDescription: t('widget_management_save_message.error'),
          },
        });
      }
    },
    [dataSources, widgetSchema, initialWidgetValues, patchWidget, postWidget]
  );

  const dynamicSchema = useMemo(
    () =>
      !isWidgetSchemaLoading &&
      !isEmpty(widgetSchema) &&
      Object.entries(widgetSchema.schema ?? {}).map(([key, widget]) => {
        const formObject = generateFormObject({ widget, prefix: 'attributes' });
        const schema =
          formObject.widget === FormItemType.SINGLE ? formObject : get(formObject, key, {});

        return (
          <SchemaManager key={key} schema={schema} t={t} control={control} disabled={isSuperUser} />
        );
      }),
    [widgetSchema, isWidgetSchemaLoading, generateFormObject, control]
  );

  useUnmount(() => {
    reset({});
  });

  return (
    <AkinonBox
      className="widget-management"
      title={t('widget_management_title')}
      description={t('widget_management_description')}
      bannerColor={Color.DODGER_BLUE}
    >
      <AkinonSpin spinning={isWidgetSchemaLoading && isWidgetFetching} tip={t('loading')}>
        <AkinonForm
          className="box-primary form-box widget-management__form"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <AkinonFormItem control={control} label={t('name')} name={FormKey.NAME} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('name') })} />
          </AkinonFormItem>
          <AkinonFormItem control={control} label={t('template')} name={FormKey.TEMPLATE} required>
            <AkinonInput placeholder={t('enter_variable', { variable: t('template') })} />
          </AkinonFormItem>
          {dynamicSchema}
          <AkinonFormItem>
            <AkinonButton
              type="primary"
              disabled={isSuperUser}
              htmlType="button"
              onClick={handleSubmit(onSubmit)}
              className="widget-management__save-button"
              loading={isPostingWidget || isPatchingWidget}
            >
              {t('save_and_continue').toUpperCase()}
            </AkinonButton>
          </AkinonFormItem>
        </AkinonForm>
      </AkinonSpin>
    </AkinonBox>
  );
};

export default WidgetManagement;
