import { findIsUserRoleAuthorized, getUserRole } from '@common/auth';
import { AppShellAppType, AppTypeToAppShellType } from '@common/index';
import Link from '@components/Link';
import { liveVersionUrlPredicates, urlUserRoleMap, UserRole } from '@constants/auth';
import { RouteUrls } from '@constants/routeUrls';
import { IconTableImport } from '@tabler/icons-react';
import { Tooltip } from 'antd';
import React from 'react';

export const getMenuItems = ({ t, user, isLiveAppVersion, apps, configs, collapsed }) => {
  const userRole = getUserRole({ user });

  const configEntries = Object.fromEntries(configs?.entries?.() ?? []);
  const fullPageApps = apps?.filter(
    (app) => app.type === AppTypeToAppShellType[AppShellAppType.FULL_PAGE]
  );

  const appShellMenuItems =
    fullPageApps?.map((app) => {
      const config = configEntries?.[app.id];
      const appPath = `/app/${app.slug}`;
      return {
        key: appPath,
        label: app.name,
        icon: <i className="icon-entagrator" />,
        children: config?.menu?.map(
          ({ label, path }) =>
            ({
              label,
              key: appPath + '/' + path,
            }) ?? []
        ),
      };
    }) ?? [];

  const menuItems = [
    {
      key: RouteUrls.dashboard.landing,
      label: (
        <Link to={RouteUrls.dashboard.landing} className="menu-link">
          {t('dashboard')}
        </Link>
      ),
      icon: <i className="icon-dashboard" />,
    },
    {
      key: RouteUrls.productsAndCategories.landing,
      label: (
        <Link to={RouteUrls.productsAndCategories.landing} className="menu-link">
          {t('sidebar.product')}
        </Link>
      ),
      icon: <i className="icon-urunler" />,
      children: [
        {
          key: RouteUrls.productsAndCategories.addProductsFromCatalog,
          label: (
            <Link to={RouteUrls.productsAndCategories.addProductsFromCatalog} className="menu-link">
              {t('add.from.marketplace.catalog')}
            </Link>
          ),
        },
        {
          key: RouteUrls.productsAndCategories.bulkActions.landing,
          label: (
            <Link to={RouteUrls.productsAndCategories.bulkActions.landing} className="menu-link">
              {t('bulk.actions')}
            </Link>
          ),
          children: [
            {
              key: RouteUrls.productsAndCategories.bulkActions.offers,
              label: (
                <Link to={RouteUrls.productsAndCategories.bulkActions.offers} className="menu-link">
                  {t('ProductsAndCategories:bulk.actions.offer')}
                </Link>
              ),
            },
          ],
        },
        {
          key: RouteUrls.productsAndCategories.productRevisions.landing,
          label: (
            <Link
              to={RouteUrls.productsAndCategories.productRevisions.landing}
              className="menu-link"
            >
              {t('product_revisions')}
            </Link>
          ),
        },
        {
          key: RouteUrls.productsAndCategories.offerList.landing,
          label: (
            <Link to={RouteUrls.productsAndCategories.offerList.landing} className="menu-link">
              {t('offer.actions')}
            </Link>
          ),
          children: [
            {
              key: RouteUrls.productsAndCategories.products.productForm,
              label: (
                <Link
                  to={RouteUrls.productsAndCategories.products.productForm}
                  className="menu-link"
                >
                  {t('ProductsAndCategories:add.single.product')}
                </Link>
              ),
            },
            {
              key: RouteUrls.productsAndCategories.offerList.approved,
              label: (
                <Link to={RouteUrls.productsAndCategories.offerList.approved} className="menu-link">
                  {t('ProductsAndCategories:approved_offers')}
                </Link>
              ),
            },
            {
              key: RouteUrls.productsAndCategories.offerList.pendingApproval,
              label: (
                <Link
                  to={RouteUrls.productsAndCategories.offerList.pendingApproval}
                  className="menu-link"
                >
                  {t('ProductsAndCategories:product.offer.waiting.confirmation')}
                </Link>
              ),
            },
            {
              key: RouteUrls.productsAndCategories.offerList.rejected,
              label: (
                <Link to={RouteUrls.productsAndCategories.offerList.rejected} className="menu-link">
                  {t('ProductsAndCategories:product.offer.declined')}
                </Link>
              ),
            },
          ],
        },
        {
          key: RouteUrls.productsAndCategories.inventoryManagement.landing,
          label: (
            <Link
              to={RouteUrls.productsAndCategories.inventoryManagement.landing}
              className="menu-link"
            >
              {t('inventory.management')}
            </Link>
          ),
          children: [
            {
              key: RouteUrls.productsAndCategories.inventoryManagement.stocks.landing,
              label: (
                <Link
                  to={RouteUrls.productsAndCategories.inventoryManagement.stocks.landing}
                  className="menu-link"
                >
                  {t('ProductsAndCategories:stock.list')}
                </Link>
              ),
            },
            {
              key: RouteUrls.productsAndCategories.inventoryManagement.prices.landing,
              label: (
                <Link
                  to={RouteUrls.productsAndCategories.inventoryManagement.prices.landing}
                  className="menu-link"
                >
                  {t('ProductsAndCategories:price.list')}
                </Link>
              ),
            },
          ],
        },
      ],
    },
    {
      key: RouteUrls.order.landing,
      label: (
        <Link to={RouteUrls.order.landing} className="menu-link">
          {t('orders')}
        </Link>
      ),
      icon: <i className="icon-siparis" />,
      children: [
        {
          key: RouteUrls.order.ordersList,
          label: (
            <Link to={RouteUrls.order.ordersList} className="menu-link">
              {t('orders')}
            </Link>
          ),
        },
        {
          key: RouteUrls.order.packages.landing,
          label: (
            <Link to={RouteUrls.order.packages.landing} className="menu-link">
              {t('packages')}
            </Link>
          ),
          children: [
            {
              key: RouteUrls.order.packages.packageList,
              label: (
                <Link to={RouteUrls.order.packages.packageList} className="menu-link">
                  {t('Orders:package_list')}
                </Link>
              ),
            },
            {
              key: RouteUrls.order.packages.packagesWaitingForCargo,
              label: (
                <Link to={RouteUrls.order.packages.packagesWaitingForCargo} className="menu-link">
                  {t('Orders:packages_waiting_for_shipment')}
                </Link>
              ),
            },
            {
              key: RouteUrls.order.packages.packagesWaitingForDelivery,
              label: (
                <Link
                  to={RouteUrls.order.packages.packagesWaitingForDelivery}
                  className="menu-link"
                >
                  {t('Orders:packages_waiting_for_delivery')}
                </Link>
              ),
            },
          ],
        },
        {
          key: RouteUrls.order.cancellationRequests,
          label: (
            <Link to={RouteUrls.order.cancellationRequests} className="menu-link">
              {t('cancel.refund.transactions')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.finance.landing,
      label: (
        <Link to={RouteUrls.finance.landing} className="menu-link">
          {t('sidebar_finance')}
        </Link>
      ),
      icon: <i className="icon-unified" />,
      children: [
        {
          key: RouteUrls.finance.agreements,
          label: (
            <Link to={RouteUrls.finance.agreements} className="menu-link">
              {t('agreements')}
            </Link>
          ),
        },
        {
          key: RouteUrls.finance.sellerManagement.landing,
          label: (
            <Link to={RouteUrls.finance.sellerManagement.landing} className="menu-link">
              {userRole === UserRole.SUPER_USER ? t('seller.management') : t('account_management')}
            </Link>
          ),
        },
        {
          key: RouteUrls.finance.reconciliations.landing,
          label: (
            <Link to={RouteUrls.finance.reconciliations.landing} className="menu-link">
              {t('reconciliations')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.marketing.landing,
      label: (
        <Link to={RouteUrls.marketing.landing} className="menu-link">
          {t('campaigns')}
        </Link>
      ),
      icon: <i className="icon-shop" />,
      children: [
        {
          key: RouteUrls.marketing.productCollections.landing,
          label: (
            <Link to={RouteUrls.marketing.productCollections.landing} className="menu-link">
              {t('collections')}
            </Link>
          ),
        },
        {
          key: RouteUrls.marketing.campaigns.landing,
          label: (
            <Link to={RouteUrls.marketing.campaigns.landing} className="menu-link">
              {t('campaigns')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.sellerStoreManagement.landing,
      label: (
        <Link to={RouteUrls.sellerStoreManagement.landing} className="menu-link">
          {t('seller_store_management')}
        </Link>
      ),
      icon: <i className="icon-magazayonetimi" />,
    },
    {
      key: RouteUrls.reporting.landing,
      label: (
        <Link to={RouteUrls.reporting.landing} className="menu-link">
          {t('sidebar.reporting')}
        </Link>
      ),
      icon: <i className="icon-report" />,
      children: [
        {
          key: RouteUrls.reporting.salesPerformance,
          label: (
            <Link to={RouteUrls.reporting.salesPerformance} className="menu-link">
              {t('sales.performance')}
            </Link>
          ),
        },
        {
          key: RouteUrls.reporting.sellerPerformance,
          label: (
            <Link to={RouteUrls.reporting.sellerPerformance} className="menu-link">
              {t('seller.performance.dashboard')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.messages.landing,
      label: (
        <Link to={RouteUrls.messages.landing} className="menu-link">
          {t('sidebar.messages')}
        </Link>
      ),
      icon: <i className="icon-mesajlar" />,
      children: [
        {
          key: RouteUrls.messages.productMessages,
          label: (
            <Link to={RouteUrls.messages.productMessages} className="menu-link">
              {t('product.messages')}
            </Link>
          ),
        },
        {
          key: RouteUrls.messages.orderMessages,
          label: (
            <Link to={RouteUrls.messages.orderMessages} className="menu-link">
              {t('order.messages')}
            </Link>
          ),
        },
        {
          key: RouteUrls.messages.quickReplies,
          label: (
            <Link to={RouteUrls.messages.quickReplies} className="menu-link">
              {t('quick.replies')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.settings.landing,
      label: (
        <Link to={RouteUrls.settings.landing} className="menu-link">
          {t('sidebar.settings')}
        </Link>
      ),
      icon: <i className="icon-ayarlar" />,
      children: [
        {
          key: RouteUrls.settings.userManagement,
          label: (
            <Link to={RouteUrls.settings.userManagement} className="menu-link">
              {t('user_management')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.ssoLoginSettingsUrl,
          label: (
            <Link to={RouteUrls.settings.ssoLoginSettingsUrl} className="menu-link">
              {t('sso_settings.title')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.accessTokens,
          label: (
            <Link to={RouteUrls.settings.accessTokens} className="menu-link">
              {t('access_tokens')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.sellerUserManagementSettings,
          label: (
            <Link to={RouteUrls.settings.sellerUserManagementSettings} className="menu-link">
              {t('seller_user_management')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.deliverySettingsShipmentSettings,
          label: (
            <Link to={RouteUrls.settings.deliverySettingsShipmentSettings} className="menu-link">
              {t('delivery_settings')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.dynamicSettings,
          label: (
            <Link to={RouteUrls.settings.dynamicSettings} className="menu-link">
              {t('dynamic_settings')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.sellerOfferRejectReasons,
          label: (
            <Link to={RouteUrls.settings.sellerOfferRejectReasons} className="menu-link">
              {t('seller_offer_reject_reasons')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.orderRejectionDeductions,
          label: (
            <Link to={RouteUrls.settings.orderRejectionDeductions} className="menu-link">
              {t('order_rejection_deductions')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.attributeSetAndCategoryMapping,
          label: !collapsed ? (
            <Tooltip title={t('category_attribute_mapping')} placement="right">
              <Link to={RouteUrls.settings.attributeSetAndCategoryMapping} className="menu-link">
                {t('category_attribute_mapping')}
              </Link>
            </Tooltip>
          ) : (
            <Link to={RouteUrls.settings.attributeSetAndCategoryMapping} className="menu-link">
              {t('category_attribute_mapping')}
            </Link>
          ),
        },
        {
          key: RouteUrls.settings.integrationErrors,
          label: (
            <Link to={RouteUrls.settings.integrationErrors} className="menu-link">
              {t('integration_errors')}
            </Link>
          ),
        },
      ],
    },
    {
      key: RouteUrls.importExportOperations.landing,
      label: (
        <Link to={RouteUrls.importExportOperations.landing} className="menu-link">
          {t('file_management')}
        </Link>
      ),
      icon: <IconTableImport size={18} />,
    },
    {
      key: RouteUrls.support.landing,
      label: (
        <Link to={RouteUrls.support.landing} className="menu-link">
          {t('support')}
        </Link>
      ),
      icon: <i className="icon-help" />,
    },
    {
      key: RouteUrls.stockLocations.landing,
      label: (
        <Link to={RouteUrls.stockLocations.landing} className="menu-link">
          {t('locations_title')}
        </Link>
      ),
      icon: <i className="ti ti-map-pin" />,
    },
    {
      key: RouteUrls.notificationManagement.landing,
      label: (
        <Link to={RouteUrls.notificationManagement.landing} className="menu-link">
          {t('notification_management')}
        </Link>
      ),
      icon: <i className="icon-notification-management" />,
    },
    ...appShellMenuItems,
  ];

  const filteredByUserRole = filterByUserRole({ currentUserRole: userRole, menuItems });
  const filteredByAppLiveVersion = filterByAppLiveVersion({
    isLiveAppVersion,
    menuItems: filteredByUserRole,
  });

  return filteredByAppLiveVersion.map((menuItem) => ({
    ...menuItem,
    children: addIconToSubMenuItems({ subMenuItem: menuItem }),
  }));
};

function addIconToSubMenuItems({ subMenuItem, depth = 0 }) {
  if (subMenuItem?.children) {
    return subMenuItem.children.map((child) => ({
      ...child,
      icon: <i className={`ion-record ml-${depth * 3}`} />,
      children: addIconToSubMenuItems({ subMenuItem: child, depth: depth + 1 }),
    }));
  } else {
    return null;
  }
}

function filterByAppLiveVersion({ isLiveAppVersion, menuItems }) {
  return menuItems
    .map((menuItem) => {
      const isEjectedOnLiveApp =
        isLiveAppVersion && !liveVersionUrlPredicates.some((predicate) => predicate(menuItem.key));
      if (isEjectedOnLiveApp) {
        return null;
      } else if (menuItem.children) {
        return {
          ...menuItem,
          children: filterByAppLiveVersion({ isLiveAppVersion, menuItems: menuItem.children }),
        };
      } else {
        return menuItem;
      }
    })
    .filter(Boolean);
}

function filterByUserRole({ currentUserRole, menuItems }) {
  return menuItems
    .map((menuItem) => {
      const requiredUserRole = urlUserRoleMap[menuItem.key];
      const isAuthorized = findIsUserRoleAuthorized({
        currentUserRole,
        authorizedUserRole: requiredUserRole,
      });
      if (!isAuthorized) {
        return null;
      } else if (menuItem.children) {
        return {
          ...menuItem,
          children: filterByUserRole({ currentUserRole, menuItems: menuItem.children }),
        };
      } else {
        return menuItem;
      }
    })
    .filter(Boolean);
}

export function checkMenuItemHasChildren({ key, menuItems }) {
  return menuItems?.some((item) =>
    item.key === key
      ? item.children
      : item.children
        ? checkMenuItemHasChildren({ key, menuItems: item.children })
        : false
  );
}
